/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, active, page }) => {
  const data = useStaticQuery(graphql`
    query {
      strapiGlobal {
        siteName
      }

      strapiContact {
        email
        facebook
        phone
        address_street
        address_city
      }

      strapiFooter {
        promotional_title
        promotional_text
        about_title
        about_text
        about_image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 200, height: 200)
            }
          }
        }

        Contact_background_image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1920, height: 960)
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.strapiGlobal.siteName} active={active} />
      
      <div id="page" className="site">
        <main>{children}</main>
      </div>

      <Footer 
        page={page}
        siteTitle={data.strapiGlobal.siteName} 
        footerIntro={data.strapiFooter.intro} 
        contactBackgroundImage={data.strapiFooter.Contact_background_image}
        promotionalTitle={data.strapiFooter.promotional_title} 
        promotionalText={data.strapiFooter.promotional_text} 
        facebookLink={data.strapiContact.facebook}
        mailLink={data.strapiContact.email}
        phoneLink={data.strapiContact.phone}
        aboutTitle={data.strapiFooter.about_title}
        aboutText={data.strapiFooter.about_text}
        aboutImage={data.strapiFooter.about_image}
        addressStreet={data.strapiContact.address_street}
        addressCity={data.strapiContact.address_city}
        />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.string,
  page: PropTypes.string,
}

export default Layout
