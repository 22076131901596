import * as React from 'react';
import { PropTypes } from "prop-types";

// const breakpoints = [320, 400, 750, 1080, 1366, 1920];


const Image = ({ image, alt, sizes = null, loading = 'lazy', className = '', style = {} }) => {

    return (<div className={'image-wrapper ' + className} style={{ ...style, backgroundColor: image.backgroundColor, overflow: 'hidden' }}>
        <picture>
            {image.images.sources.map(source => (
                <source srcset={source.srcSet} sizes={sizes || source.sizes} type={source.type} />
            ))}

            <img
                loading={loading}
                src={image.images.fallback.src}
                srcSet={image.images.fallback.srcSet}
                sizes={sizes || image.images.fallback.sizes}
                alt={alt}
                width={image.width}
                height={image.height}
                />
        </picture>
    </div>);
}


Image.propTypes = {
    image: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
    loading: PropTypes.string,
    className: PropTypes.string,
    sizes: PropTypes.string,
    style: PropTypes.object
}

Image.defaultProps = {
    image: null,
    alt: null,
    sizes: null,
    loading: 'lazy',
    className: '',
    style: {}
}

export default Image
