import { Link } from "gatsby";
import * as React from "react"
import Image from "./image";
 
const Promotional = ({ title, description, link, linkText, icon, image }) => {
  return (
    <section className={'promotional-bar-section'}> 
      {image && (
        <Image
          image={image.localFile.childImageSharp.gatsbyImageData}
          sizes={'100vw'}
          alt={``}
        />
      )}
      <div className="container">
        <div className="promotional-bar-content">
          {icon && <span className={'fa fa-' + icon} style={{ fontSize: 32, marginBottom: 20 }}></span>}
          <h3 className="entry-title">{title}</h3>
          {description && (<p>{description}</p>)}
        </div>
        {linkText && (<div className="promotional-bar-button">
          <Link to={link} className="box-button">{linkText}</Link>
        </div>)}
      </div>
    </section>
  );
}

Promotional.propTypes = {
	// siteTitle: PropTypes.string,
}

Promotional.defaultProps = {
  // siteTitle: ``,
}

export default Promotional
