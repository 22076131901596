import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Promotional from "./promotional"
import About from "./about"
import Image from "./image"

function encode(data) {
	return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&")
}

const handleSubmit = (event) => {
	event.preventDefault();

	event.target.classList.add('is-loading');

  var name = document.getElementById('name').value;
  var email = document.getElementById('email').value;
  var message = document.getElementById('message').value;

  if (!name || !email || !message) {
    alert('Gelieve alle velden in te vullen.')
    return;
  }

	document.getElementById('submit').disabled = 'disabled';
	document.getElementById('submit').innerText = 'Verzenden..';

	fetch("/", {
		method: "POST",
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: encode({
			"form-name": event.target.getAttribute("name"),
			name: name,
			email: email,
			message: message,
			page: window.location.href
		})
	})
	.then(() => {
		document.getElementById('submit').innerText = 'Bericht verzonden!';
		event.target.classList.add('is-completed');
	})
	.catch(error => {
		document.getElementById('submit').disabled = '';
		alert(error);
	})
	.finally(() => {
		event.target.classList.remove('is-loading');
	});
}

const Footer = ({ siteTitle, contactBackgroundImage, aboutTitle, aboutText, aboutImage, promotionalTitle, promotionalText, facebookLink, mailLink, phoneLink, addressStreet, addressCity, page }) => (
	<>
		<Promotional
			title={promotionalTitle}
			description={promotionalText}
			link="/contact"
			linkText="Contacteer ons"
			/>
		<About
			title={aboutTitle}
			text={aboutText}
			image={aboutImage} />
		<div className="container"></div>
		<footer className="site-footer">
			<div className="widget-area">
				{contactBackgroundImage && (<Image
							image={contactBackgroundImage.localFile.childImageSharp.gatsbyImageData}
							sizes={'100vw'}
							alt=""
						/>
				)}
				<div className="container">
					<div className="row">
						<div className="custom-col-6">
							<h3 style={{textAlign: 'center', marginBottom: 30, marginTop: -20}}>Een vraag of opmerking?</h3>
							<form name="contact_footer" method="POST" className="row" data-netlify="true" onSubmit={handleSubmit}>
								<div className="custom-col-6">
									<label htmlFor="name">Uw naam</label>
									<input type="text" id="name" name="name" />
								</div>
								<div className="custom-col-6">
									<label htmlFor="email">Uw email</label>
									<input type="email" id="email" name="email" />
								</div>
								<div className="custom-col-12">
									<label htmlFor="message">Uw bericht</label>
									<textarea id="message" name="message" rows="8"></textarea>
								</div>
								<button className="box-button" id="submit" type="submit">Verzenden</button>
								<input type="hidden" name="form-name" value="contact_footer" />
								<div style={{display: 'none'}}>
									<label htmlFor="page">Pagina</label>
									<input type="text" id="page" name="page" value={page} />
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className="site-generator" style={{ paddingBottom: 0 }}>
				<div className="container" style={{ display: 'block' }}>
					<div className="row" style={{ borderBottom: 'solid 1px rgba(255, 255, 255, .3)' }}>
						<nav className="custom-col-6" style={{ paddingBottom: 40 }}>
							<ul>
								<li><Link to="/" aria-current="page">Home</Link></li>
								<li><Link to="/aanbod" aria-current="page">Aanbod</Link></li>
								<li><Link to="/syndicus" aria-current="page">Syndicus</Link></li>
								<li><Link to="/blog" aria-current="page">Blog</Link></li>
								<li><Link to="/contact" aria-current="page">Contact</Link></li>
								<li><a target="_blank" rel="noreferrer" href="https://mcusercontent.com/2b1067facf9c5175f4f8ecdbe/files/52968bbd-fe66-4a4e-b7cd-27202a52e706/Sectorprotocol_NL_3.pdf">Sectorprotocol voor plaatsbezoeken in de vastgoedsector</a></li>
							</ul>
						</nav>
						<nav className="custom-col-6 extra" style={{ paddingBottom: 40 }}>
							<ul>
								<li>BA en borgstelling via NV AXA Belgium (polisnr. 730.390.160)</li>
								<li>Onderworpen aan de <a target="_blank" rel="noreferrer" href="http://www.biv.be/plichtenleer" title="Plichtenleer van de erkende vastgoedmakelaar">deontologische code van het BIV</a></li>
								<li><a target="_blank" rel="noreferrer" href="http://www.biv.be/plichtenleer" title="Deontologische code van het BIV">BIV-erkend vastgoedmakelaar-syndicus en vastgoedmakelaar-bemiddelaar</a></li>
								<li>Als erkend vastgoedmakelaar-bemiddelaar onderworpen aan de <a target="_blank" rel="noreferrer" href="http://www.biv.be/plichtenleer" title="Deontologie van de vastgoedmakelaar">BIV-plichtenleer</a></li>
								<li>Onderhevig aan de <a target="_blank" rel="noreferrer" href="http://www.biv.be/plichtenleer" title="BIVdeontologie">plichtenleer van de vastgoedmakelaar </a></li>
								<li>Toezichthoudende autoriteit: Beroepsinstituut van Vastgoedmakelaars, Luxemburgstraat 16 B te 1000 Brussel</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
			<div className="site-generator">
				<div className="container">
					<div className="row" style={{ width: '100%' }}>
						<span className="copy-right custom-col-6">
							<span className="copyright-text">© {new Date().getFullYear()}</span>
							<span className="sep"> | </span>
							<span><Link to="/">{siteTitle}</Link> - <a href="mailto:info@lisimmo.be">info@lisimmo.be</a></span>
							{/*
								<span style={{ display: 'none' }} className="sep"> | </span>
								<button style={{ display: 'none' }} href="#" onclick={event => { window.gaOptout && window.gaOptout(); event.target.remove(); }}>Do not track me</a>
							*/}
							<br/>
							<span>Bergerstraat 74, 3680 Neeroeteren</span>
							<span className="sep"> | </span>
							<span>BTW 0786.771.453</span>
							<span className="sep"> | </span>
							<span>BIV 510 403</span>
							<br/>
							<span>RPR Tongeren</span>
						</span>
						<div className="inline-social-icons social-links custom-col-6">
							<ul>
								<li className="menu-item menu-item-type-custom menu-item-object-custom">
									<a rel="noreferrer" target="_blank" href={'https://www.google.com/maps/place/' + addressStreet + ',' + addressCity}>{addressStreet}, {addressCity}</a>
								</li>
								<li className="menu-item menu-item-type-custom menu-item-object-custom">
									<a href={'tel:' + phoneLink}>Telefoon</a>
								</li>
								<li className="menu-item menu-item-type-custom menu-item-object-custom">
									<a href={'mailto:' + mailLink}>E-mail</a>
								</li>
								<li className="menu-item menu-item-type-custom menu-item-object-custom">
									<a rel="noreferrer" target="_blank" href={facebookLink}>Facebook</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</>
)

Footer.propTypes = {
	siteTitle: PropTypes.string,
	siteUrl: PropTypes.string,
	footerIntro: PropTypes.string,
	page: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
  siteUrl: ``,
  footerIntro: ''
}

export default Footer
