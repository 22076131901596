import * as React from "react"
import Image from "./image"

const About = ({ title, text, image }) => (
  <section className="about-section" style={{paddingTop: 60, paddingBottom: 60}}> 
    <div className="container">
      {/* <header className="entry-header heading">
        <h2 className="entry-title">{title}</h2>
      </header> */}
      <div className="row">
        <div className="custom-col-12">
          <div className="container" style={{ width: 800, marginLeft: 'auto', marginRight: 'auto' }}>
            <div className="row">
              <div className="custom-col-12" style={{ textAlign: 'center' }}>
                <Image
                  style={{ maxWidth: 130, borderRadius: 100, margin: '0 auto 20px', overflow: 'hidden' }}
                  image={image.localFile.childImageSharp.gatsbyImageData}
                  sizes={'130px'}
                  alt={`Afbeelding van ${title}`}
                />
              </div>
              <div className="custom-col-12">
                <div className="entry-content gallery" style={{ textAlign: 'center' }}>
                  {text}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default About
