import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle, siteDescription, active }) => (
  <header id="masthead" className="site-header">
    <div className="container">
      <div className="hgroup-wrap">
        <section className="site-branding"> 
          <Link to="/" className="custom-logo-link" rel="home" aria-current="page">
            <img src="/images/logo-header.jpeg" className="custom-logo" alt="Site Logo" width="130" height="50" />
          </Link> 
          <p className="site-title" style={{ display: 'none' }}>
            <Link to="/" rel="home">{siteTitle}</Link>
          </p>
          <span className="site-description">{siteDescription}</span>
        </section>

        <div id="menu-toggle" className="toggle-menu">
          <label htmlFor="check">
            <input type="checkbox" id="check" onClick={e => document.getElementById('menu-toggle').classList.toggle('active', e.target.checked)}/> 
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        <div className="hgroup-right"> 
          <div id="navbar" className="navbar"> 
            <nav id="site-navigation" className="navigation main-navigation">
              <div className="menu-top-menu-container" style={{display: 'block'}}>
                <div className="menu-main-menu-container">
                  <ul id="menu-main-menu" className="menu">
                    <li className={'menu-item menu-item-type-post_type menu-item-object-page' + ( active === 'home' ? ' current-menu-item' : '')}>
                      <Link to="/" aria-current="page">Home</Link>
                    </li>
                    <li className={'menu-item menu-item-type-post_type menu-item-object-page' + ( active === 'aanbod' ? ' current-menu-item' : '')}>
                      <Link to="/aanbod" aria-current="page">Aanbod</Link>
                    </li>
                    <li className={'menu-item menu-item-type-post_type menu-item-object-page' + ( active === 'syndicus' ? ' current-menu-item' : '')}>
                      <Link to="/syndicus" aria-current="page">Syndicus</Link>
                    </li>
                    <li className={'menu-item menu-item-type-post_type menu-item-object-page' + ( active === 'blog' ? ' current-menu-item' : '')}>
                      <Link to="/blog" aria-current="page">Blog</Link>
                    </li>
                    <li className={'menu-item menu-item-type-post_type menu-item-object-page' + ( active === 'contact' ? ' current-menu-item' : '')}>
                      <Link to="/contact" aria-current="page">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
}

export default Header
